import * as React from "react"
import Template from "components/template";
import * as config from 'utils/configs/monka';

// markup
const Monka = () => {
  return (
    <Template config={config} />
  )
}

export default Monka
